<template>
    <div>
        <b-table-lite borderless small stacked
                      :fields="fields"
                      :items="tableData">
            <!--APPLICATION-->
            <template v-slot:cell(applicationHeader)="row">(<b-link @click="$emit('edit')">edit</b-link>)</template>
            <template v-slot:cell(submittedDate)="row">{{row.item.appliedDate | formatDate}}</template>
            <template v-slot:cell(statusDate)="row">{{row.item.statusDate | formatDate}}</template>
            <template v-slot:cell(status)="row">{{row.item.status.label}}</template>
            <template v-slot:cell(statusDate)="row">{{row.item.statusDate | formatDate}}</template>
            <template v-slot:cell(statusReviewer)="row">{{row.item.reviewer}}</template>
            <!--SESSION-->
            <template v-slot:cell(sessionHeader)="row"></template>
            <template v-slot:cell(sessionAssignment)="row">{{row.item.session.label}}</template>
            <!--MEMBER-->
            <template v-slot:cell(memberHeader)="row"></template>
            <template v-slot:cell(memberName)="row">{{row.item.member | formatFullName}}</template>
            <template v-slot:cell(memberUsername)="row">{{row.item.member.username}}</template>
            <template v-slot:cell(memberUbcId)="row">{{row.item.member | formatUbcId}}</template>
            <template v-slot:cell(memberLocal)="row">{{row.item.member.local}}</template>
            <template v-slot:cell(memberUnionStatus)="row">{{row.item.workspace.member.unionStatus}}</template>
            <template v-slot:cell(memberClassification)="row">{{row.item.member.classification}}</template>
            <template v-slot:cell(memberOshaStatus)="row">{{ (row.item.member.oshaClassScheduleId) ? 'Confirmed' : 'Review Needed'}}</template>
            <template v-slot:cell(memberVaccineStatus)="row">{{ formatCovidVaccineStatus(row.item) }}</template>
            <template v-slot:cell(memberTitle)="row">{{row.item.member.title}}</template>
            <template v-slot:cell(memberAddress)="row"><span v-html="memberAddress"></span></template>
            <template v-slot:cell(memberEmail)="row">{{row.item.member.email}}</template>
            <template v-slot:cell(memberPhone)="row">{{row.item.member.phone.formatted}}</template>
            <template v-slot:cell(memberEmergencyContact)="row"><span v-html="memberEmergencyContact"></span></template>
            <!--MENTOR-->
            <template v-slot:cell(mentorHeader)="row"></template>
            <template v-slot:cell(mentorName)="row">{{row.item.mentor | formatFullName}}</template>
            <template v-slot:cell(mentorUsername)="row">{{row.item.mentor.username}}</template>
            <template v-slot:cell(mentorUbcId)="row">{{row.item.mentor | formatUbcId}}</template>
            <template v-slot:cell(mentorTitle)="row">{{row.item.mentor.title}}</template>
            <template v-slot:cell(mentorAddress)="row"><span v-html="mentorAddress"></span></template>
            <template v-slot:cell(mentorEmail)="row">{{row.item.mentor.email}}</template>
            <template v-slot:cell(mentorPhone)="row">{{row.item.mentor.phone.formatted}}</template>
            <template v-slot:cell(mentorEmergencyContact)="row"><span v-html="mentorEmergencyContact"></span></template>
            <!--CONTRACTOR-->
            <template v-slot:cell(contractorHeader)="row"></template>
            <template v-slot:cell(contractorName)="row">{{row.item.contractor.name}}</template>
            <template v-slot:cell(contractorType)="row">{{row.item.contractor | formatType}}</template>
            <template v-slot:cell(contractorAddress)="row"><span v-html="contractorAddress"></span></template>
            <template v-slot:cell(contactName)="row">{{row.item.contractor | formatContactName}}</template>
            <template v-slot:cell(contactTitle)="row">{{row.item.contractor | formatContactTitle}}</template>
            <template v-slot:cell(contactEmail)="row">{{row.item.contractor | formatContactEmail}}</template>
            <template v-slot:cell(contactPhone)="row">{{row.item.contractor | formatContactPhone}}</template>
        </b-table-lite>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator';
    import {Application, NULL_APPLICATION} from "@/model/enrollment";
    import {sprintf} from 'sprintf-js';
    import formatters from '@/util/formatters';
    import _ from 'underscore';
    import {Phone} from "../../../model/phone";
    import {format} from 'date-fns';
    import {mkDate} from '@/util/formatters';

    @Component({
        filters: {
            formatUbcId: (member) => {
                return _.isEmpty(member.ubcId) ? '' : sprintf('U-%s-%s',
                    member.ubcId.substring(1,5), member.ubcId.substring(5,member.ubcId.length));
            },
            formatType: (contractor) => {
                return _.isEmpty(contractor.type) ? '' : contractor.type.type;
            },
            formatFullName: (member) => {
                return sprintf('%s %s %s %s',
                    (_.isEmpty(member.firstName) ? '' : member.firstName),
                    (_.isEmpty(member.middleName) ? '' : member.middleName),
                    (_.isEmpty(member.lastName) ? '' : member.lastName),
                    (_.isEmpty(member.suffix) ? '' : member.suffix))
                    .replace(/  +/g, ' ').trim();
            },
            formatContactName: (contractor) => {
                if (_.isEmpty(contractor) || _.isEmpty(contractor.contacts) || _.isEmpty(contractor.contacts[0])) {
                    return '';
                }
                else {
                    const contact = contractor.contacts[0];
                    return sprintf('%s %s',
                        (_.isEmpty(contact.firstName) ? '' : contact.firstName),
                        (_.isEmpty(contact.lastName) ? '' : contact.lastName))
                        .replace(/  +/g, ' ').trim();
                }
            },
            formatContactTitle: (contractor) => {
                if (_.isEmpty(contractor) || _.isEmpty(contractor.contacts) || _.isEmpty(contractor.contacts[0])) {
                    return '';
                }
                else {
                    return contractor.contacts[0].title;
                }
            },
            formatContactPhone: (contractor) => {
                if (_.isEmpty(contractor) || _.isEmpty(contractor.contacts) || _.isEmpty(contractor.contacts[0])) {
                    return '';
                }
                else {
                    const phone = contractor.contacts[0].phone instanceof Phone ? contractor.contacts[0].phone :
                        Phone.create(contractor.contacts[0].phone);
                    return phone.formatted;
                }
            },
            formatContactEmail: (contractor) => {
                if (_.isEmpty(contractor) || _.isEmpty(contractor.contacts) || _.isEmpty(contractor.contacts[0])) {
                    return '';
                }
                else {
                    return contractor.contacts[0].email;
                }
            },
            formatDate: (date) => {
                const dateTime = formatters.mkDate(date);
                const formattedDateTime = formatters.dateTime(dateTime, true);
                return formattedDateTime;
            }
        }
    })

    export default class ApplicationDetails extends Vue {
        @Prop({type: Application, default: null}) value;

        mounted() {
            // console.log(this.value);
        }

        get application() {
            return !this.value ? NULL_APPLICATION.clone() : this.value;
        }

        get tableData() {
            return [this.application];
        }

        get fields() {
            //Specify key-value for display fields
            const fields = [{
                //Application
                key: 'applicationHeader',
                label: 'Application'
            }, {
                key: 'submittedDate',
                label: 'Application Submitted Date'
            },{
                key: 'statusDate',
                label: 'Application Processed Date'
            }, {
                key: 'status',
                label: 'Application Status'
            }, {
                key: 'statusDate',
                label: 'Application Status Date'
            }, {
                key: 'statusReviewer',
                label: 'Application Processed By'
            }, {
                //Session
                key: 'sessionHeader',
                label: 'Session',
                variant: 'dark'
            }, {
                key: 'sessionAssignment',
                label: 'Session Assignment'
            }, {
                //Member
                key: 'memberHeader',
                label: 'Member',
                variant: 'dark'
            }, {
                key: 'memberName',
                label: 'Member Name'
            }, {
                key: 'memberUsername',
                label: 'Member Username'
            }, {
                key: 'memberUbcId',
                label: 'Member UBC ID'
            }, {
                key: 'memberLocal',
                label: 'Member Local Union'
            }, {
                key: 'memberUnionStatus',
                label: 'Member Local Union Status'
            }, {
                key: 'memberClassification',
                label: 'Member Classification'
            }, {
                key: 'memberOshaStatus',
                label: 'Member OSHA 30 Prerequisite Complete?'
            }, {
                key: 'memberVaccineStatus',
                label: 'Member COVID-19 Vaccine Verified?'
            }, {
                key: 'memberTitle',
                label: 'Member Job Title'
            }, {
                key: 'memberAddress',
                label: 'Member Address'
            }, {
                key: 'memberEmail',
                label: 'Member Email'
            }, {
                key: 'memberPhone',
                label: 'Member Mobile Phone'
            }, {
                key: 'memberEmergencyContact',
                label: 'Member Emergency Contact'
            }, {
                //Mentor
                key: 'mentorHeader',
                label: 'Mentor',
                variant: 'dark'
            }, {
                key: 'mentorName',
                label: 'Mentor Name'
            }, {
                key: 'mentorUsername',
                label: 'Mentor Username'
            }, {
                key: 'mentorUbcId',
                label: 'Mentor UBC ID'
            },  {
                key: 'mentorTitle',
                label: 'Mentor Job Title'
            }, {
                key: 'mentorAddress',
                label: 'Mentor Address'
            }, {
                key: 'mentorEmail',
                label: 'Mentor Email'
            }, {
                key: 'mentorPhone',
                label: 'Mentor Mobile Phone'
            }, {
                key: 'mentorEmergencyContact',
                label: 'Mentor Emergency Contact'
            }, {
                //Contractor
                key: 'contractorHeader',
                label: 'Contractor',
                variant: 'dark'
            }, {
                key: 'contractorName',
                label: 'Original Contractor Name'
            }, {
                key: 'contractorType',
                label: 'Original Contractor Type'
            }, {
                key: 'contractorAddress',
                label: 'Original Contractor Address'
            }, {
                key: 'contactName',
                label: 'Original Contact Name'
            }, {
                key: 'contactTitle',
                label: 'Original Contact Title'
            }, {
                key: 'contactEmail',
                label: 'Original Contact Email'
            }, {
                key: 'contactPhone',
                label: 'Original Contact Phone'
            }, {
                key: 'actions',
                label: ''
            }];
            return fields;
        }

        get memberEmergencyContact() {
            return this.formatEmergencyContact(this.application.member);
        }

        get mentorEmergencyContact() {
            return this.formatEmergencyContact(this.application.mentor);
        }

        get memberAddress() {
            return this.formatAddress(this.application.member);
        }

        get mentorAddress() {
            return this.formatAddress(this.application.mentor);
        }

        get contractorAddress() {
            return this.formatAddress(this.application.contractor);
        }

        formatEmergencyContact(member) {
            const contact = member.emergencyContact;
            if (_.isNull(contact)) {
                return 'Not available';
            }
            const relationship = sprintf(' (%s)', contact.relationship);
            const dayPhone = sprintf('<br />Day: %s', contact.dayPhone.formatted);
            const nightPhone = sprintf('<br />Night: %s', contact.nightPhone.formatted);
            return sprintf('%s%s%s%s', contact.name, relationship, dayPhone, nightPhone)
                .replace(/  +/g, ' ').trim();
        }

        formatAddress(object) {
            return !object.address1 ? '' : sprintf('%s%s<br/>%s, %s %s',
                object.address1,
                !!object.address2 ? '<br/>' + object.address2 : '',
                object.city,
                _.isObject(object.state) ? object.state.stateAbbreviation : object.state,
                object.zip
            );
        }

        formatCovidVaccineStatus(application) {

            if (!application.session.requireVaccine) {
                return 'Not Applicable';
            }

            const member = application.member;

            if (!member.vaccineVerified && !member.vaccinePrerequisiteMet) {
                return 'Review Needed';
            }

            if (!member.vaccineVerified && member.vaccinePrerequisiteMet) {
                return 'Override';
            }

            const date = format(mkDate(member.vaccineVerifiedDate), 'M/d/yyyy');
            return `Confirmed ${date} (${member.vaccineVerifiedByUsername})`;
        }
    }
</script>

<style scoped>
</style>