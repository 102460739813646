<template>
    <div>
        <b-table-lite borderless small stacked
                      :fields="fields"
                      :items="tableData">
            <!--APPLICATION (not using workspace for these read-only fields-->
            <template v-slot:cell(applicationHeader)="row">
                <p>(<b-link @click="$emit('cancel')">cancel</b-link>)</p>
            </template>
            <template v-slot:cell(submittedDate)="row">{{row.item.appliedDate | formatDate}}</template>
            <template v-slot:cell(statusDate)="row">{{row.item.statusDate | formatDate}}</template>
            <template v-slot:cell(status)="row">{{row.item.status.label}}</template>
            <template v-slot:cell(statusDate)="row">{{row.item.statusDate | formatDate}}</template>
            <template v-slot:cell(statusReviewer)="row">{{row.item.reviewer}}</template>
            <!--SESSION-->
            <template v-slot:cell(sessionHeader)="row">
            </template>
            <template v-slot:cell(sessionAssignment)="row">
                <b-form-group :state="errorState('session')" invalid-feedback="Required">
                    <b-select :state="errorState('session')" size="sm"
                              :options="sessions"
                              v-model="row.item.workspace.session" />
                </b-form-group>
            </template>
            <!--MEMBER-->
            <template v-slot:cell(memberHeader)="row">
            </template>
            <template v-slot:cell(memberName)="row">{{row.item.workspace.member | formatFullName}}</template>
            <template v-slot:cell(memberUsername)="row">
                <template v-if="!row.item.workspace.member.disableUsername">
                    <b-form-group
                            :state="isMemberUsernameValid"
                            :invalid-feedback="invalidMemberUsernameFeedback">
                        <b-input
                                :state="isMemberUsernameValid"
                                v-model="row.item.workspace.member.username"
                                placeholder="Member SCT username" size="sm" />
                    </b-form-group>
                </template>
                <template v-else>{{row.item.workspace.member.username}}</template>
            </template>
            <template v-slot:cell(memberUbcId)="row">
                <b-form-group :state="errorState('memberUbcId')" invalid-feedback="Required (trainee cannot be an enabled SCT mentor OR an active SCT trainee)">
                    <b-input :state="errorState('memberUbcId')" disabled
                             v-model="row.item.workspace.member.formattedUbcId" size="sm" />
                </b-form-group>
            </template>
            <template v-slot:cell(memberLocal)="row">{{row.item.workspace.member.local}}</template>
            <template v-slot:cell(memberUnionStatus)="row">
                <b-form-group :state="errorState('memberUnionStatus')" invalid-feedback="Cannot enroll unless in good-standing with local union">
                    <template v-if="!errorState('memberUnionStatus')">
                        <b-input-group>
                            <b-input :state="errorState('memberUnionStatus')"
                                     v-model="row.item.workspace.member.unionStatus"
                                     size="sm" disabled />
                        </b-input-group>
                    </template>
                    <template v-else>{{row.item.workspace.member.unionStatus}}</template>
                </b-form-group>
            </template>
            <template v-slot:cell(memberClassification)="row">{{row.item.workspace.member.classification}}</template>
            <template v-slot:cell(memberOshaStatus)="row">
                <b-form-group :state="errorState('memberOshaStatus')" invalid-feedback="Cannot enroll without confirming member OSHA prerequisite status">
                    <span v-html="oshaUrl"></span>
                    <b-checkbox :state="errorState('memberOshaStatus')"
                                :disabled="osha30Disabled"
                                id="oshaStatus"
                                name="oshaStatus"
                                label="Confirmed"
                                v-model="row.item.workspace.member.oshaPrerequisiteMet">Confirmed</b-checkbox>
                </b-form-group>
            </template>
            <template v-slot:cell(memberVaccineStatus)="row" v-if="vaccineRequired()">
                <b-form-group :state="errorState('memberVaccineStatus')" invalid-feedback="Cannot enroll without confirming member COVID-19 verification prerequisite status">
                    <b-checkbox :state="errorState('memberVaccineStatus')"
                                :disabled="vaccinePrerequisiteDisabled"
                                id="vaccineStatus"
                                name="vaccineStatus"
                                :label="vaccinePrerequisiteLabel"
                                v-model="row.item.workspace.member.vaccinePrerequisiteMet">{{vaccinePrerequisiteLabel}}</b-checkbox>
                </b-form-group>
            </template>
            <template v-slot:cell(memberTitle)="row">
                <template v-if="!row.item.workspace.member.disableTitle">
                    <b-form-group :state="errorState('memberTitle')" invalid-feedback="Required">
                        <b-input :state="errorState('memberTitle')" v-model="row.item.workspace.member.title" size="sm" />
                    </b-form-group>
                </template>
                <template v-else>{{row.item.workspace.member.title}}</template>
            </template>
            <template v-slot:cell(memberAddress)="row"><span v-html="memberAddress"></span></template>
            <template v-slot:cell(memberEmail)="row">
                <template v-if="!row.item.workspace.member.disableEmail">
                    <b-form-group :state="errorState('memberEmail')" invalid-feedback="Valid email required">
                        <b-input :state="errorState('memberEmail')" v-model="row.item.workspace.member.email" size="sm" />
                    </b-form-group>
                </template>
                <template v-else>{{row.item.workspace.member.email}}</template>
            </template>
            <template v-slot:cell(memberPhone)="row">
                <template v-if="!row.item.workspace.member.disablePhone">
                    <b-form-group :state="errorState('memberPhone')" invalid-feedback="Valid phone number required">
                        <phone-number id="member-phone"
                                      :state="errorState('memberPhone')"
                                      input-class="form-control form-control-sm"
                                      v-model="row.item.workspace.member.phone.formatted"
                                      show-status  />
                    </b-form-group>
                </template>
                <template v-else>{{row.item.workspace.member.phone.formatted}}</template>
            </template>
            <template v-slot:cell(memberEmergencyContact)="row">
                <template v-if="!row.item.workspace.member.disableEmergencyContact">
                    <b-form-group :state="errorState('memberEmergencyContact')" invalid-feedback="Required">
                        <b-input :state="errorState('memberEmergencyContact')"
                                 v-model="row.item.workspace.member.emergencyContact.name"
                                 size="sm" placeholder="Emergency Contact" />
                    </b-form-group>
                    <b-form-group :state="errorState('memberEmergencyContactRelationship')" invalid-feedback="Required">
                        <b-input :state="errorState('memberEmergencyContactRelationship')"
                                 v-model="row.item.workspace.member.emergencyContact.relationship"
                                 size="sm" placeholder="Emergency Contact Relationship" />
                    </b-form-group>
                </template>
                <template v-else><span v-html="memberEmergencyContact"></span></template>
                <template v-if="!row.item.workspace.member.emergencyContact.disableDayPhone">
                    <b-form-group :state="errorState('memberEmergencyContactDayPhone')" invalid-feedback="Valid phone number required">
                        <phone-number id="member-emergency-contact-day-phone"
                                      :state="errorState('memberEmergencyContactDayPhone')"
                                      input-class="form-control form-control-sm"
                                      v-model="row.item.workspace.member.emergencyContact.dayPhone.formatted"
                                      show-status  />
                    </b-form-group>
                </template>
                <template v-else>{{row.item.workspace.member.emergencyContact.dayPhone.formatted}}<br /></template>
                <template v-if="!row.item.workspace.member.emergencyContact.disableNightPhone">
                    <b-form-group :state="errorState('memberEmergencyContactNightPhone')" invalid-feedback="Valid phone number required">
                        <phone-number id="member-emergency-contact-night-phone"
                                      :state="errorState('memberEmergencyContactNightPhone')"
                                      input-class="form-control form-control-sm"
                                      v-model="row.item.workspace.member.emergencyContact.nightPhone.formatted"
                                      show-status  />
                    </b-form-group>
                </template>
                <template v-else>{{row.item.workspace.member.emergencyContact.nightPhone.formatted}}</template>
            </template>
            <!--MENTOR-->
            <template v-slot:cell(mentorHeader)="row">
            </template>
            <template v-slot:cell(mentorAssignment)="row">
                <b-form-group :state="errorState('mentorAssignment')" invalid-feedback="Required (mentor must be distinct from trainee and contractor contact, and cannot be an active SCT trainee)">
                    <b-select :state="errorState('mentorAssignment')" 
                              :options="mentors" size="sm" 
                              v-model="row.item.workspace.mentor" />
                </b-form-group>
            </template>
            <template v-slot:cell(mentorName)="row">
                <template v-if="!row.item.workspace.mentor.disableMentorFields">
                    <b-form-group :state="errorState('mentorFirstName')" invalid-feedback="Required">
                        <b-input :state="errorState('mentorFirstName')" size="sm" 
                                 v-model="row.item.workspace.mentor.firstName" 
                                 placeholder="Mentor First Name" />
                    </b-form-group>
                    <b-form-group>
                        <b-input size="sm" 
                                 v-model="row.item.workspace.mentor.middleName" 
                                 placeholder="Middle Name" />
                    </b-form-group>
                    <b-form-group :state="errorState('mentorLastName')" invalid-feedback="Required">
                        <b-input :state="errorState('mentorLastName')" size="sm" 
                                 v-model="row.item.workspace.mentor.lastName" 
                                 placeholder="Mentor Last Name" />
                    </b-form-group>
                </template>
                <template v-else>{{row.item.workspace.mentor | formatFullName}}</template>
            </template>
            <template v-slot:cell(mentorUsername)="row">
                <template v-if="!row.item.workspace.mentor.disableUsername && !row.item.status.isApproved">
                    <b-form-group
                            :state="isMentorUsernameValid"
                            :invalid-feedback="invalidMentorUsernameFeedback">
                        <b-input
                                :state="isMentorUsernameValid"
                                v-model="row.item.workspace.mentor.username"
                                placeholder="Mentor SCT username" size="sm" />
                    </b-form-group>
                </template>
                <template v-else>{{row.item.workspace.mentor.username}}</template>
            </template>
            <template v-slot:cell(mentorUbcId)="row">
                <template v-if="!row.item.workspace.mentor.disableMentorFields">
                    <b-form-group>
                        <b-input-group>
                            <ubc-id :id="'mentor-ubc-id'"
                                    input-class="form-control form-control-sm"
                                    v-model="row.item.workspace.mentor.ubcId"
                                    @submitted="lookupMentorUbcId(row.item.workspace.mentor)" />
                            <b-input-group-append>
                                <b-button size="sm" variant="primary"
                                          @click="lookupMentorUbcId(row.item.workspace.mentor)">Lookup</b-button>
                                <b-button size="sm" variant="warning"
                                          v-if="!!row.item.workspace.mentor.ubcId"
                                          @click="resetMentor">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </template>
                <template v-else>{{row.item.workspace.mentor.ubcId}}</template>
            </template>
            <template v-slot:cell(mentorTitle)="row">
                <template v-if="!row.item.workspace.mentor.disableMentorFields">
                    <b-form-group :state="errorState('mentorTitle')" invalid-feedback="Required">
                        <b-input :state="errorState('mentorTitle')" size="sm" 
                                 v-model="row.item.workspace.mentor.title" />
                    </b-form-group>
                </template>
                <template v-else>{{row.item.workspace.mentor.title}}</template>
            </template>
            <template v-slot:cell(mentorAddress)="row">
                <template v-if="!row.item.workspace.mentor.disableMentorFields">
                    <b-form-group :state="errorState('mentorAddress1')" invalid-feedback="Required">
                        <b-input :state="errorState('mentorAddress1')"size="sm" 
                                 v-model="row.item.workspace.mentor.address1" 
                                 placeholder="Address 1" />
                    </b-form-group>
                    <b-form-group>
                        <b-input size="sm" 
                                 v-model="row.item.workspace.mentor.address2" 
                                 placeholder="Address 2" />
                    </b-form-group>
                    <b-form-group :state="errorState('mentorCity')" invalid-feedback="Required">
                        <b-input :state="errorState('mentorCity')" size="sm" 
                                 v-model="row.item.workspace.mentor.city" 
                                 placeholder="City" />
                    </b-form-group>
                    <b-form-group :state="errorState('mentorState')" invalid-feedback="Required">
                        <state-select :state="errorState('mentorState')" 
                                      :id="'state'" size="sm" 
                                      v-model="row.item.workspace.mentor.state" />
                    </b-form-group>
                    <b-form-group :state="errorState('mentorZip')" invalid-feedback="Required">
                        <b-input :state="errorState('mentorZip')" size="sm" 
                                 v-model="row.item.workspace.mentor.zip" 
                                 placeholder="ZIP/Postal Code" />
                    </b-form-group>
                </template>
                <template v-else><span v-html="mentorAddress"></span></template>
            </template>
            <template v-slot:cell(mentorEmail)="row">
                <template v-if="!row.item.workspace.mentor.disableMentorFields && !row.item.workspace.mentor.disableEmail">
                    <b-form-group :state="errorState('mentorEmail')" invalid-feedback="Valid email required">
                        <b-input :state="errorState('mentorEmail')" size="sm" 
                                 v-model="row.item.workspace.mentor.email" />
                    </b-form-group>
                </template>
                <template v-else>{{row.item.workspace.mentor.email}}</template>
            </template>
            <template v-slot:cell(mentorPhone)="row">
                <template v-if="!row.item.workspace.mentor.disableMentorFields && !row.item.workspace.mentor.disablePhone">
                    <b-form-group :state="errorState('mentorPhone')" invalid-feedback="Valid phone number required">
                        <phone-number id="mentor-mobile-phone"
                                      :state="errorState('mentorPhone')"
                                      :show-status="!application.mentor.disableMentorFields && !application.mentor.disablePhone"
                                      input-class="form-control form-control-sm"
                                      v-model="row.item.workspace.mentor.phone.formatted" />
                    </b-form-group>
                </template>
                <template v-else>{{row.item.workspace.mentor.phone.formatted}}</template>
            </template>
            <template v-slot:cell(mentorEmergencyContact)="row">
                <template v-if="!row.item.workspace.mentor.disableMentorFields && !row.item.workspace.mentor.disableEmergencyContact">
                    <b-form-group :state="errorState('mentorEmergencyContact')" invalid-feedback="Required">
                        <b-input :state="errorState('mentorEmergencyContact')"
                                 v-model="row.item.workspace.mentor.emergencyContact.name"
                                 size="sm" placeholder="Name" />
                    </b-form-group>
                    <b-form-group :state="errorState('mentorEmergencyContactRelationship')" invalid-feedback="Required">
                        <b-input :state="errorState('mentorEmergencyContactRelationship')"
                                 v-model="row.item.workspace.mentor.emergencyContact.relationship"
                                 size="sm" placeholder="Relationship" />
                    </b-form-group>
                </template>
                <template v-else><span v-html="mentorEmergencyContact"></span></template>
                <template v-if="!row.item.workspace.mentor.disableMentorFields && !row.item.workspace.mentor.emergencyContact.disableDayPhone">
                    <b-form-group :state="errorState('mentorEmergencyContactDayPhone')" invalid-feedback="Valid phone number required">
                        <phone-number id="mentor-emergency-contact-day-phone"
                                      :state="errorState('mentorEmergencyContactDayPhone')"
                                      input-class="form-control form-control-sm"
                                      v-model="row.item.workspace.mentor.emergencyContact.dayPhone.formatted"
                                      :show-status="!application.mentor.disableMentorFields && !application.mentor.emergencyContact.disableDayPhone"
                                      placeholder="Day Phone" />
                    </b-form-group>
                </template>
                <template v-else>Day Phone: {{row.item.workspace.mentor.emergencyContact.dayPhone.formatted}}</template>
                <br />
                <template v-if="!row.item.workspace.mentor.disableMentorFields && !row.item.workspace.mentor.emergencyContact.disableNightPhone">
                    <b-form-group :state="errorState('mentorEmergencyContactNightPhone')" invalid-feedback="Valid phone number required">
                        <phone-number id="mentor-emergency-contact-night-phone"
                                      :state="errorState('mentorEmergencyContactNightPhone')"
                                      input-class="form-control form-control-sm"
                                      v-model="row.item.workspace.mentor.emergencyContact.nightPhone.formatted"
                                      :show-status="!application.mentor.disableMentorFields && !application.mentor.emergencyContact.disableNightPhone"
                                      placeholder="Night Phone" />
                    </b-form-group>
                </template>
                <template v-else>Night Phone: {{row.item.workspace.mentor.emergencyContact.nightPhone.formatted}}</template>
            </template>
            <!--CONTRACTOR-->
            <template v-slot:cell(contractorHeader)="row">
            </template>
            <template v-slot:cell(contractorAssignment)="row">
                <b-form-group :state="errorState('contractorAssignment')" invalid-feedback="Required - If the Trainee's Contractor is not listed under Contractor Assignment, you need to create a new Contractor first">
                    <b-select :state="errorState('contractorAssignment')" 
                              :options="contractors" size="sm" 
                              v-model="row.item.workspace.contractor">
                    </b-select>
                </b-form-group>
            </template>
            <template v-slot="row">Original Entries</template>
            <template v-slot:cell(contractorName)="row">{{row.item.contractor.name}}</template>
            <template v-slot:cell(contractorType)="row">{{row.item.contractor | formatType}}</template>
            <template v-slot:cell(contractorAddress)="row"><span v-html="contractorAddress"></span></template>
            <template v-slot:cell(contactName)="row">{{row.item.contractor | formatContactName}}</template>
            <template v-slot:cell(contactTitle)="row">{{row.item.contractor | formatContactTitle}}</template>
            <template v-slot:cell(contactEmail)="row">{{row.item.contractor | formatContactEmail}}</template>
            <template v-slot:cell(contactPhone)="row">{{row.item.contractor | formatContactPhone}}</template>
            <template v-slot:cell(actions)="row">
                <b-form-group>
                    <b-button-group size="sm">
                        <!--Hacky log button is only available in dev mode-->
                        <b-button v-if="devMode"
                                  variant="secondary"
                                  @click="logApplication">Log</b-button>
                        <!--Cancel button is always available-->
                        <b-button variant="primary"
                                  @click="$emit('cancel')">Cancel</b-button>
                        <!--Decline button is available when not in declined status-->
                        <b-button v-if="!row.item.status.isDeclined"
                                  variant="danger" 
                                  @click="showDeclineModal">Decline</b-button>
                        <!--Interim save button is available when in applied status-->
                        <b-button v-if="row.item.status.isApplied"
                                  variant="info"
                                  @click="showSaveModal">Save</b-button>
                        <!--Enroll button is available when not in enrolled status-->
                        <b-button v-if="!row.item.status.isEnrolled"
                                  variant="success" 
                                  :disabled="disableApproval"
                                  @click="showApproveModals">Enroll</b-button>
                    </b-button-group>
                </b-form-group>
                <!--DECLINE MODAL-->
                <b-modal :id="declineModalId"
                         no-close-on-backdrop
                         no-close-on-esc
                         size="lg"
                         title="Decline Application"
                         header-bg-variant="dark"
                         header-text-variant="white"
                         button-size="sm"
                         cancel-variant="primary"
                         ok-variant="danger"
                         scrollable
                         @ok="$emit('decline')">
                    <b-card bg-variant="light">
                        <template>
                            <b-form-group :state="errorStateDecline('status')"
                                          invalid-feedback="Required">
                                <p>Please select the reason for declining this application:</p>
                                <b-select :state="errorStateDecline('status')"
                                          :options="applicationDeclinedStatuses" 
                                          v-model="row.item.workspace.status" />
                            </b-form-group>
                            <b-form-group v-if="row.item.workspace.status.requiresReason"
                                          :state="errorStateDecline('statusReason')"
                                          invalid-feedback="Required for selected status">
                                <b-input :state="errorStateDecline('statusReason')"
                                         v-model="row.item.workspace.statusReason"
                                         placeholder="Describe why this application is declined"/>
                            </b-form-group>
                            <!-- Confirm new Trainee status for members that already exist in SCT database (i.e. valid userProfileId) -->
                            <b-form-group v-if="!row.item.disableTraineeRoleStatus && row.item.workspace.status.isDeclined"
                                          :state="errorStateDecline('memberRoleStatus')"
                                          invalid-feedback="Required">
                                <p>Please select the status for the Trainee user profile:</p>
                                <b-select :state="errorStateDecline('memberRoleStatus')"
                                          :options="traineeProfileStatus" 
                                          v-model="row.item.workspace.member.roleStatus" />
                            </b-form-group>
                        </template>
                    </b-card>
                    <template v-slot:modal-cancel>
                        <b-button variant="cancel"
                                  size="sm"
                                  @click="revertDecline">Cancel</b-button>
                    </template>
                    <template v-slot:modal-ok>
                        <b-button variant="danger"
                                  size="sm"
                                  :disabled="disableDecline"
                                  @click="$emit('decline')">Decline</b-button>
                    </template>
                </b-modal>
            </template>
        </b-table-lite>
    </div>
</template>


<script>
    import {Vue, Component, Prop} from 'vue-property-decorator';
    import store from '@/store/store';
    import {Application, NULL_APPLICATION, NULL_STATUS} from "@/model/enrollment";
    import {NEW_MENTOR, NULL_MEMBER} from "@/model/member";
    import {sprintf} from 'sprintf-js';
    import {format, isAfter} from 'date-fns';
    import formatters, {errorModalOptions, errorToastOptions} from "../../../util/formatters";
    import {UbcId, UBCID_REGEX} from '@/components/shared/UbcId';
    import _ from 'underscore';
    import PhoneNumber from '@/components/shared/PhoneNumber.vue';
    import StateSelect from '@/components/shared/StateSelect.vue';
    import {escapeSingleQuotes, mkDate} from "@/util/formatters";
    import memberDao from '@/dao/member_dao';
    import userDao from "@/dao/user_dao";
    import {Phone} from "../../../model/phone";

    @Component({
        components: {
            UbcId,
            PhoneNumber,
            StateSelect
        },
        data: function () {
            const self = this;
            return {
                vaccineAutoVerified: false,
                application: !self.value ? NULL_APPLICATION.clone() : self.value
            };
        },
        filters: {
            formatUbcId: (member) => {
                return _.isEmpty(member.ubcId) ? '' : sprintf('U-%s-%s',
                    member.ubcId.substring(1,5), member.ubcId.substring(5,member.ubcId.length));
            },
            formatFullName: (member) => {
                return sprintf('%s %s %s %s',
                    (_.isEmpty(member.firstName) ? '' : member.firstName),
                    (_.isEmpty(member.middleName) ? '' : member.middleName),
                    (_.isEmpty(member.lastName) ? '' : member.lastName),
                    (_.isEmpty(member.suffix) ? '' : member.suffix))
                    .replace(/  +/g, ' ').trim();
            },
            formatType: (contractor) => {
                return _.isEmpty(contractor.type) ? '' : contractor.type.type;
            },
            formatContactName: (contractor) => {
                if (_.isEmpty(contractor) || _.isEmpty(contractor.contacts) || _.isEmpty(contractor.contacts[0])) {
                    return '';
                }
                else {
                    const contact = contractor.contacts[0];
                    return sprintf('%s %s',
                        (_.isEmpty(contact.firstName) ? '' : contact.firstName),
                        (_.isEmpty(contact.lastName) ? '' : contact.lastName))
                        .replace(/  +/g, ' ').trim();
                }
            },
            formatContactTitle: (contractor) => {
                if (_.isEmpty(contractor) || _.isEmpty(contractor.contacts) || _.isEmpty(contractor.contacts[0])) {
                    return '';
                }
                else {
                    return contractor.contacts[0].title;
                }
            },
            formatContactPhone: (contractor) => {
                if (_.isEmpty(contractor) || _.isEmpty(contractor.contacts) || _.isEmpty(contractor.contacts[0])) {
                    return '';
                }
                else {
                    const phone = contractor.contacts[0].phone instanceof Phone ? contractor.contacts[0].phone :
                        Phone.create(contractor.contacts[0].phone);
                    return phone.formatted;
                }
            },
            formatContactEmail: (contractor) => {
                if (_.isEmpty(contractor) || _.isEmpty(contractor.contacts) || _.isEmpty(contractor.contacts[0])) {
                    return '';
                }
                else {
                    return contractor.contacts[0].email;
                }
            },
            formatDate: (date) => {
                const dateTime = formatters.mkDate(date);
                const formattedDateTime = formatters.dateTime(dateTime, true);
                return formattedDateTime;
            }
        },
        asyncComputed: {
            isMemberUsernameValid: {
                async get() {
                    //A missing username is invalid
                    if (_.isEmpty(this.application.workspace.member.username)) {
                        this.application.workspace.member.validUsername = false;
                        return false;
                    }
                    //Existing users do not need username validation
                    if (this.application.workspace.member.userProfileId > 0) {
                        this.application.workspace.member.validUsername = true;
                        return true;
                    }
                    try {
                        const result = await userDao.validUsername(this.application.workspace.member.username);
                        this.application.workspace.member.validUsername = result;
                        return result;
                    }
                    catch (error) {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    }
                },
                watch: ['application.workspace.member.username']
            },
            isMentorUsernameValid: {
                async get() {
                    //A missing username is invalid
                    if (_.isEmpty(this.application.workspace.mentor.username)) {
                        this.application.workspace.mentor.validUsername = false;
                        return false;
                    }
                    //Existing users do not need username validation
                    if (this.application.workspace.mentor.userProfileId > 0) {
                        this.application.workspace.mentor.validUsername = true;
                        return true;
                    }
                    try {
                        const result = await userDao.validUsername(this.application.workspace.mentor.username);
                        this.application.workspace.mentor.validUsername = result;
                        return result;
                    }
                    catch (error) {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    }
                },
                watch: ['application.workspace.mentor.username']
            },
        },
        computed: {
            vaccinePrerequisiteLabel: function () {
                switch (true) {
                    case !this.vaccineRequired():
                        return 'Not Applicable';

                    case this.application.workspace.member.vaccineVerified:
                        const member = this.application.workspace.member;
                        const date = format(mkDate(member.vaccineVerifiedDate), 'M/d/yyyy');
                        return `Confirmed ${date} (${member.vaccineVerifiedByUsername})`;

                    default:
                        return 'Override';
                }
            },
            vaccinePrerequisiteDisabled: function () {
                return !this.vaccineRequired() || this.vaccineAutoVerified;
            }
        },
        methods: {
            refreshUsernameValidation() {
                this.$asyncComputed.isMemberUsernameValid.update();
                this.$asyncComputed.isMentorUsernameValid.update();
            },
            vaccineRequired: function () {
                //Only verify member.vaccinePrerequisiteMet if chosen session requires vaccine
                return this.application.workspace.session.requireVaccine || false
            },
        }
    })

    export default class ApplicationEdit extends Vue {
        @Prop({type: Application, default: NULL_APPLICATION}) value;
        @Prop({type: Boolean, default: true}) showButtons;
        // vaccineAutoVerified = false;

        get osha30Disabled() {
            return true === ((this.application || {}).member || {}).oshaPrerequisiteMet &&
                (((this.application || {}).workspace || {}).member || {}).oshaPrerequisiteMet;
        }

        //This whole module has many opportunities for improvement, but wiring debugging tools ain't one of 'em anymore!
        devMode = false;
        logApplication() {
            console.log(this.application);
        }

        async mounted() {
            try {
                await store.dispatch('organizations/loadOrganizations');
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
            //Refresh trainee data
            try {
                const memberUpdates = await memberDao.lookupMember(this.application.workspace.member.ubcId);
                this.application.workspace.member.refreshData(memberUpdates);
                this.vaccineAutoVerified = memberUpdates.vaccineVerified ||
                    this.application.workspace.member.vaccinePrerequisiteMet ||
                    this.application.status.isEnrolled;

                this.application.workspace.member.vaccinePrerequisiteMet = this.vaccineAutoVerified;
                //Check trainee for SCT role
                if (this.application.workspace.member.isEnabled) {
                    //Note: validation has moved to Application Controller
                    //This check is invalid as it doesn't consider whether the mentor is truly active
                    //Trainee can't be an active SCT mentor
                    // if (this.application.workspace.member.isAMentor) {
                    //     await this.$bvModal.msgBoxOk('Selected member is an active SCT mentor and is ' +
                    //         'ineligible to participate as a trainee until his/her mentorship is complete. When ' +
                    //         'his/her mentorship is complete, his/her user profile must be upgraded from the Users ' +
                    //         'screen.');
                    // }
                    if (this.application.workspace.member.isAMentor) {
                        //Trainee can't be an active SCT trainee with an active trainee-mentor relationship
                        const activeTrainee = await userDao.activeTrainee(this.application.workspace.member.userProfileId);
                        this.application.workspace.member.activeTrainee = activeTrainee;
                        if (activeTrainee) {
                            await this.$bvModal.msgBoxOk('Selected member is an active SCT trainee with an active ' +
                                'mentor relationship. When his/her training is complete, his/her mentor relationship may be ' +
                                'deactivated from the Users screen.');
                        }
                    }
                }
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
            //Refresh mentor data if possible (if not UBC ID, use username)
            try {
                if (!_.isEmpty(this.application.workspace.mentor.ubcId)) {
                    const mentorUpdates = await memberDao.lookupMentor(this.application.workspace.mentor.ubcId);
                    this.application.workspace.mentor.refreshData(mentorUpdates);
                    // if (this.application.workspace.mentor.refreshData(mentorUpdates)) {
                    //     await this.$bvModal.msgBoxOk('Updates from database applied for selected Mentor.');
                    // }
                }
                else if (!_.isEmpty(this.application.workspace.mentor.username)) {
                    const mentorUpdates = await memberDao.lookupByUsername(this.application.workspace.mentor.username);
                    this.application.workspace.mentor.refreshPartialData(mentorUpdates);
                    // if (this.application.workspace.mentor.refreshPartialData(mentorUpdates)) {
                    //     await this.$bvModal.msgBoxOk('Updates from database applied for selected Mentor.');
                    // }
                }
                else {
                    this.application.workspace.mentor.proposeUsername();
                }
                //Mentor can't be an active SCT trainee
                if (this.application.workspace.mentor.isEnrolled && this.application.workspace.mentor.isATrainee) {
                    await this.$bvModal.msgBoxOk('Selected member is an enrolled SCT trainee and is ' +
                        'ineligible to participate as a mentor until his/her training is complete. When ' +
                        'his/her training is complete, his/her user profile must be upgraded from the Users ' +
                        'screen.');
                }
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }

        get invalidMemberUsernameFeedback() {
            return _.isEmpty(this.application.workspace.member.username) ?
                'Username is required' :
                'The provided username is either invalid or is already in use';
        }

        get invalidMentorUsernameFeedback() {
            return _.isEmpty(this.application.workspace.mentor.username) ?
                'Username is required' :
                'The provided username is either invalid or is already in use';
        }

        get declineModalId() {
            return sprintf('decline-application-modal-%d', this.application.id);
        }

        //Restore fields changed for declining
        revertDecline() {
            this.application.workspace.status = this.application.status;
            if (this.application.workspace.member.traineeStatus) {
                delete this.application.workspace.member.traineeStatus;
            }
            if (this.application.workspace.statusReason) {
                delete this.application.workspace.statusReason;
            }
        }

        async showDeclineModal() {
            //If a non-decline status has been previously selected, clear it
            if (!this.application.workspace.status.isDeclined) {
                this.application.workspace.status = NULL_STATUS;
            }
            this.$bvModal.show(this.declineModalId);
        }

        async showSaveModal() {
            const saveCheck = await this.$bvModal.msgBoxConfirm(
                'Select OK to save your changes.', {
                title: 'Confirm Interim Save',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
            if (!saveCheck) {
                return;
            }
            this.$emit('save');
        }

        async showApproveModals() {
            //Check - add new mentor
            if (this.application.workspace.mentor.isNew || this.application.workspace.mentor.isNewMentor) {
                const mentorCheck = await this.$bvModal.msgBoxConfirm(sprintf(
                    'This application cannot be approved until a SCT mentor user profile is created for %s. ' +
                    'Select Yes to create a SCT mentor user profile for the selected mentor. ' +
                    'To go back and select a different mentor, select No. ',
                    this.application.workspace.mentor.fullName), {
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    title: 'The selected mentor doesn\'t have an active mentor profile in SCT',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
                if (!mentorCheck) {
                    return;
                }
            }
            //Check - available seats
            if (1 > this.application.workspace.session.availableSeats) {
                const sessionCheck = await this.$bvModal.msgBoxConfirm(sprintf(
                    'All seats have been filled for %s. ' +
                    'Select OK to override the class max and enroll the trainee in the selected session. ' +
                    'To go back and select a different session, select Cancel. ',
                    this.application.workspace.session.label), {
                        title: 'The selected session is at or above the enrollment cap',
                        noCloseOnBackdrop: true,
                        noCloseOnEsc: true,
                        headerBgVariant: 'dark',
                        headerTextVariant: 'white'
                    });
                if (!sessionCheck) {
                    return;
                }
            }
            else {
                const enrollCheck = await this.$bvModal.msgBoxConfirm(sprintf(
                    '%s has %d of %d seats available. ' +
                    'Select OK to enroll the trainee in the selected session. ' +
                    'To go back and select a different session, select Cancel.',
                    this.application.workspace.session.label,
                    this.application.workspace.session.availableSeats,
                    this.application.workspace.session.maxSeats), {
                    title: 'Confirm Enrollment',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
                if (!enrollCheck) {
                    return;
                }
            }
            this.$emit('approve');
        }

        //Field validation for enrolling applications
        get disableApproval() {
            this.refreshUsernameValidation();
            for (let field of Object.keys(this.application.enrollmentErrors)) {
                if (this.errorState(field) === false) {
                    switch (field) {
                        case 'memberVaccineStatus':
                            if (this.application.session.requireVaccine) {
                                return true;
                            }

                            // TODO:
                            break;

                        default:
                            return true;
                    }
                }
            }
            return false;
        }

        errorState(field) {
            return !this.application.enrollmentErrors[field];
        }

        //Field validation for declining applications
        get disableDecline() {
            for (let field of Object.keys(this.application.declineErrors)) {
                if (this.errorStateDecline(field) === false) {
                    return true;
                }
            }
            return false;
        }

        errorStateDecline(field) {
            return !this.application.declineErrors[field];
        }

        async lookupMentorUbcId(person) {
            if (!UBCID_REGEX.test(person.ubcId)) {
                return this.$bvModal.msgBoxOk('Please enter a valid UBC ID.', {
                    title: 'Invalid UBC ID',
                    size: 'sm',
                    buttonSize: 'sm',
                    centered: true
                });
            }
            try {
                const member = await memberDao.lookupMentor(person.ubcId);
                //Could not locate mentor in SCT/CITF/Train
                if (_.isEqual(member, NULL_MEMBER)) {
                    return this.$bvModal.msgBoxOk('We are unable to locate your UBC Journeyman record. ' +
                        'If you feel this is in error, please contact your Local Union to review their records.');
                }
                //Mentor already exists in SCT - DO NOT BLOCK, could be a disabled mentor
                // else if (!member.isNewMentor) {
                //     return this.$bvModal.msgBoxOk('UBC ID is already associated with a mentor profile. ' +
                //         'Please select mentor from drop-down list provided: ' + member.mentorLabel);
                // }
                //Mentor exists as a trainee in SCT
                else if (member.isEnrolled && member.isATrainee) {
                    await this.$bvModal.msgBoxOk('Selected member is an enrolled SCT trainee and is ' +
                        'ineligible to participate as a mentor until his/her training is complete. When ' +
                        'his/her training is complete, his/her user profile must be upgraded from the Users ' +
                        'screen.');
                }
                //Create new
                else {

                    if (!member.username) {
                        member.username = person.username;
                    }
                    
                    if (!member.title) {
                        member.title= person.title
                    }

                    if (!member.email) {
                        member.email=person.email
                    }

                    if (!_.isObject(member.emergencyContact) || member.emergencyContact.id == 0) {
                        member.emergencyContact = person.emergencyContact
                    }

                    if (!_.isObject(member.phone) || member.phone.id == 0) {
                        member.phone = person.phone
                    }

                    person.copyFrom(member);
                }
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }

        resetMentor() {
            this.application.workspace.mentor = NULL_MEMBER.clone();
        }

        get contractorTypes() {
            const contractorTypes = this.$store.getters['organizations/getContractorTypes'];
            const contractorTypesList = _.chain(contractorTypes)
                .map((type) => {
                    return {
                        value: type,
                        text: type.type
                    };
                }).value();
            return contractorTypesList;
        }

        get traineeProfileStatus() {
            const traineeProfileStatus = this.$store.getters['users/traineeDisabledStatuses'];
            const traineeProfileStatusList = _.chain(traineeProfileStatus)
                .map((s) => {
                    return {
                        value: s,
                        text: s.status
                    };
                }).value();
            return traineeProfileStatusList;
        }

        get applicationStatuses() {
            const applicationStatuses = this.$store.getters['applications/getApplicationStatuses'];
            return _.chain(applicationStatuses)
                .map((s) => {
                    return {
                        value: s,
                        text: sprintf('%s - %s', s.type, s.description)
                    };
                })
                .value();
        }

        get applicationDeclinedStatuses() {
            const applicationStatuses = this.$store.getters['applications/getApplicationStatuses'];
            return _.chain(applicationStatuses)
                .filter((s) => s.isDeclined)
                .map((s) => {
                    return {
                        value: s,
                        text: s.description
                    };
                })
                .value();
        }

        get memberEmergencyContact() {
            return this.formatEmergencyContact(this.application.workspace.member);
        }

        get mentorEmergencyContact() {
            return this.formatEmergencyContact(this.application.workspace.mentor);
        }

        get memberAddress() {
            return this.formatAddress(this.application.workspace.member);
        }

        get mentorAddress() {
            return this.formatAddress(this.application.workspace.mentor);
        }

        get contractorAddress() {
            return this.formatAddress(this.application.contractor);
        }

        formatEmergencyContact(member) {
            const contact = member.emergencyContact;
            if (_.isNull(contact) || _.isNull(contact.name)) {
                return '';
            }
            const emergencyContact = sprintf('%s (%s) <br />', contact.name,
                _.isNull(contact.relationship) ? 'Unknown' : contact.relationship);
            return emergencyContact;
        }

        formatAddress(object) {
            if (!object.address1) {
                return '';
            }
            else {
                const address = sprintf('%s%s<br/>%s, %s %s',
                    object.address1,
                    !!object.address2 ? '<br/>' + object.address2 : '',
                    object.city,
                    _.isObject(object.state) ? object.state.stateAbbreviation : object.state,
                    object.zip);
                return escapeSingleQuotes(address);
            }
        }

        get oshaUrl() {
            const member = this.application.member;
            if (_.isNull(member) || _.isEmpty(member.tvcHash)) {
                return '';
            }
            else {
                const baseHtml = '<a href="https://train%s.carpenters.org/tvc?x=%s" target="_blank">OSHA TVC Card</a><br />';
                const env = this.$store.getters.appInfo.environment;
                switch (env) {
                    case 'prod':
                        return sprintf(baseHtml, '', member.tvcHash);
                    case 'trn':
                        return sprintf(baseHtml, '-training', member.tvcHash);
                    case 'test':
                    default:
                        if (env !== 'test') {
                            console.log(env);
                        }
                        return sprintf(baseHtml, '-test', member.tvcHash);
                }
            }
        }

        get sessions() {
            const prelimSessions = this.$store.getters['tracks/getProgram1sNoYear'];
            const selectedSession = this.application.session;
            const now = new Date();

            return _.chain(prelimSessions)
                    .filter(s => (!!selectedSession && selectedSession.sessionId === s.sessionId) || isAfter(s.dates[0], now))
                    .map(s => ({value: s, text: s.availableSeatsLabel}))
                    .sortBy(s => s.value.dates[0])
                    .value();
        }

        get mentors() {
            const mentors = this.$store.getters['mentors/mentorsAsMembers'];
            let currentMentor = this.application.workspace.mentor.clone();
            let mentorList = _.chain(mentors)
                .sortBy((m) => m.lastName)
                //Remove matched mentor so direct reference can be added later
                .filter((m) => {
                    return !currentMentor.isSamePerson(m);
                })
                .map((m) => {
                    return {
                        value: m,
                        text: m.mentorLabel
                    };
                })
                .value();
            //Add current mentor (back) to list
            if (currentMentor.isNewMentor) {
                mentorList.unshift({
                    value: currentMentor,
                    text: sprintf('%s (NEW)', currentMentor.mentorLabel)
                });
            }
            else {
                mentorList.unshift({
                    value: currentMentor,
                    text: currentMentor.mentorLabel
                });
            }
            //Add a generic NEW ENTRY option
            mentorList.unshift({
                value: NEW_MENTOR.clone(),
                text: 'Enter a New Mentor'
            });
            return mentorList;
        }

        get contractors() {
            const organizations = this.$store.getters['organizations/getOrganizations'];
            const contractorList = _.chain(organizations)
                .filter((org) => !!org.type)
                .sortBy((org) => org.name)
                .map((org) => {
                    return {
                        value: org,
                        text: org.name
                    };
                })
                .value();
            return contractorList;
        }

        get tableData() {
            return [this.application];
        }

        get fields() {
            const fields = [{
                //Application
                key: 'applicationHeader',
                label: 'Application'
            }, {
                key: 'submittedDate',
                label: 'Application Submitted Date'
            }, {
                key: 'statusDate',
                label: 'Application Processed Date'
            }, {
                key: 'status',
                label: 'Application Status'
            }, {
                key: 'statusDate',
                label: 'Application Status Date'
            }, {
                key: 'statusReviewer',
                label: 'Application Processed By'
            }, {
                //Session
                key: 'sessionHeader',
                label: 'Session',
                variant: 'dark'
            }, {
                key: 'sessionAssignment',
                label: 'Session Assignment'
            }, {
                //Member
                key: 'memberHeader',
                label: 'Member',
                variant: 'dark'
            }, {
                key: 'memberName',
                label: 'Member Name'
            }, {
                key: 'memberUsername',
                label: 'Member Username'
            }, {
                key: 'memberUbcId',
                label: 'Member UBC ID'
            }, {
                key: 'memberVaccineStatus',
                label: 'COVID-19 Vaccine Verified'
            }, {
                key: 'memberLocal',
                label: 'Member Local Union'
            }, {
                key: 'memberUnionStatus',
                label: 'Member Local Union Status'
            }, {
                key: 'memberClassification',
                label: 'Member Classification'
            }, {
                key: 'memberOshaStatus',
                label: 'Member OSHA 30 Prerequisite Complete?'
            }, {
                key: 'memberTitle',
                label: 'Member Job Title'
            }, {
                key: 'memberAddress',
                label: 'Member Address'
            }, {
                key: 'memberEmail',
                label: 'Member Email'
            }, {
                key: 'memberPhone',
                label: 'Member Mobile Phone'
            }, {
                key: 'memberEmergencyContact',
                label: 'Member Emergency Contact'
            }, {
                //Mentor
                key: 'mentorHeader',
                label: 'Mentor',
                variant: 'dark'
            }, {
                key: 'mentorAssignment',
                label: 'Mentor Assignment'
            }, {
                key: 'mentorName',
                label: 'Mentor Name'
            }, {
                key: 'mentorUsername',
                label: 'Mentor Username'
            }, {
                key: 'mentorUbcId',
                label: 'Mentor UBC ID'
            },  {
                key: 'mentorTitle',
                label: 'Mentor Job Title'
            }, {
                key: 'mentorAddress',
                label: 'Mentor Address'
            }, {
                key: 'mentorEmail',
                label: 'Mentor Email'
            }, {
                key: 'mentorPhone',
                label: 'Mentor Mobile Phone'
            }, {
                key: 'mentorEmergencyContact',
                label: 'Mentor Emergency Contact'
            }, {
                //Contractor
                key: 'contractorHeader',
                label: 'Contractor',
                variant: 'dark'
            }, {
                key: 'contractorAssignment',
                label: 'Contractor Assignment'
            }, {
                key: 'contractorName',
                label: 'Original Contractor Name'
            }, {
                key: 'contractorType',
                label: 'Original Contractor Type'
            }, {
                key: 'contractorAddress',
                label: 'Original Contractor Address'
            }, {
                key: 'contactName',
                label: 'Original Contact Name'
            }, {
                key: 'contactTitle',
                label: 'Original Contact Title'
            }, {
                key: 'contactEmail',
                label: 'Original Contact Email'
            }, {
                key: 'contactPhone',
                label: 'Original Contact Phone'
            }, {
                key: 'actions',
                label: ''
            }];
            return fields;
        }
    }
</script>

<style scoped>
</style>