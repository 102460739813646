<template>
    <div>
        <b-row>
            <b-col>
                <b-card class="mb-3">
                    <b-card-body>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="application-status" label="Application Status:">
                                    <b-input-group>
                                        <b-select id="application-status" size="sm" :options="applicationStatusTypes" @select="results" v-model="applicationStatusFilter" />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-ubc-id" label="UBC ID:">
                                    <b-input-group>
                                        <b-input id="search-ubc-id"
                                                 size="sm"
                                                 placeholder="Member's UBC ID"
                                                 v-model="ubcIdFilter"/>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="ubcIdFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-member-name" label="Member Name:">
                                    <b-input-group>
                                        <b-input id="search-member-name"
                                                 size="sm"
                                                 placeholder="Member's First or Last Name"
                                                 v-model="memberNameFilter" />
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="memberNameFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-local-union" label="Local Union:">
                                    <b-input-group>
                                        <b-select :options="locals"
                                                  id="search-local-union" size="sm"
                                                  v-model="localUnionFilter">
                                            <template #first>
                                                <option :value="null">Any</option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="localUnionFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-union-status" label="Union Status:">
                                    <b-input-group>
                                        <b-input id="search-union-status"
                                                 size="sm"
                                                 placeholder="Member's Union Status"
                                                 v-model="unionStatusFilter" />
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="unionStatusFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-classification" label="Classification:">
                                    <b-input-group>
                                        <b-select :options="classifications"
                                                  id="search-classification" size="sm"
                                                  v-model="classificationFilter">
                                            <template #first>
                                                <option :value="null">Any</option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="classificationFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-mentor-name" label="Mentor Name:">
                                    <b-input-group>
                                        <b-input id="search-mentor-name"
                                                 size="sm"
                                                 placeholder="Mentor's First or Last Name"
                                                 v-model="mentorNameFilter" />
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="mentorNameFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-contractor-name" label="Contractor Name:">
                                    <b-input-group>
                                        <b-input id="search-contractor-name"
                                                 size="sm"
                                                 placeholder="Contractor's Name"
                                                 v-model="contractorNameFilter" />
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="contractorNameFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-state" label="Contractor State/Province:">
                                    <b-input-group>
                                        <state-select :id="'search-state'"
                                                      size="sm"
                                                      v-model="contractorStateFilter"/>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="contractorStateFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-applied-date" label="Applied Date Range:">
                                    <b-input-group>
                                        <picker-of-dates id="search-applied-date"
                                                         format="M/d/yyyy"
                                                         v-model="appliedDateFilter"
                                                         placeholder="Filter by Applied Date"
                                                         size="sm" range/>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-processed-date" label="Processed Date Range:">
                                    <b-input-group>
                                        <picker-of-dates id="search-processed-date"
                                                         format="M/d/yyyy"
                                                         v-model="processedDateFilter"
                                                         placeholder="Filter by Processed Date"
                                                         size="sm" range/>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-button-group size="sm">
                                    <b-button variant="info" @click="clearFilters">Clear</b-button>
                                </b-button-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col>
                            <b-row>
                                <b-col cols="4">
                                    <b-pagination :total-rows="results.length" :per-page="perPage" v-model="currentPage"/>
                                </b-col>
                                <b-col cols="8">
                                    <b-form-group :label="'Records Per Page: ' + perPage">
                                        <b-input type="range" v-model="perPage" min="10" max="100"></b-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-table small striped hover bordered stacked="sm" head-variant="dark"
                                     fixed
                                     no-local-sorting
                                     :sort-by.sync="sort.by"
                                     :sort-desc.sync="sort.desc"
                                     :fields="resultFields"
                                     :items="results"
                                     :per-page="perPage"
                                     :current-page="currentPage">
                                <template v-slot:cell(ubcId)="row">{{row.item.member.ubcId}}</template>
                                <template v-slot:cell(local)="row">{{row.item.member.local | upper}}</template>
                                <template v-slot:cell(unionStatus)="row">{{row.item.member.unionStatus}}</template>
                                <template v-slot:cell(classification)="row">{{row.item.member.classification | upper}}</template>
                                <template v-slot:cell(memberName)="row">{{row.item.member.fullName}}</template>
                                <template v-slot:cell(mentorName)="row">{{row.item.mentor.fullName}}</template>
                                <template v-slot:cell(contractorName)="row">{{row.item.contractor.name}}</template>
                                <template v-slot:cell(contractorState)="row">{{row.item.contractor.state | formatState}}</template>
                                <template v-slot:cell(session)="row">{{row.item.session.label}}</template>
                                <template v-slot:cell(status)="row">{{row.item.status.type}}</template>
                                <template v-slot:cell(appliedDate)="row">{{row.item.appliedDate | formatDate}}</template>
                                <template v-slot:cell(statusDate)="row">{{row.item.statusDate | formatDate}}</template>
                                <template v-slot:cell(buttons)="row">
                                    <b-link variant="link" @click="row.toggleDetails">{{ row.detailsShowing ? 'Hide' : 'Show'}} Details</b-link>
                                </template>
                                <template v-slot:row-details="row">
                                    <application-edit v-if="row.item.editMode" v-model="row.item"
                                                      @cancel="row.item.rollback()"
                                                      @save="saveApplication(row.item)"
                                                      @approve="approveApplication(row.item)"
                                                      @decline="declineApplication(row.item)"/>
                                    <application-details v-else v-model="row.item" @edit="row.item.editMode=true"/>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-pagination :total-rows="results.length" :per-page="perPage" v-model="currentPage"/>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import {Vue, Component, Watch} from 'vue-property-decorator';
    import store from '@/store/store';
    import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
    import StateSelect from '@/components/shared/StateSelect.vue';
    import CITFDatePicker from '@/components/shared/CITFDatePicker.vue';
    import ApplicationDetails from './ApplicationDetails.vue';
    import ApplicationEdit from './ApplicationEdit.vue';
    import _ from 'underscore';
    import PickerOfDates from '@/components/shared/PickerOfDates';
    import {State} from '@/model/common';
    import {trimToNull} from '@/util/formatters';
    import formatters from '@/util/formatters';
    import {NULL_ORGANIZATION} from "@/model/organization";
    import {NULL_APPLICATION} from "../../../model/enrollment";
    import {errorModalOptions, errorToastOptions} from "../../../util/formatters";
    import {startOfDay, endOfDay, isBefore, isAfter} from 'date-fns';

    @Component({
        components: {
            CITFDatePicker,
            PickerOfDates,
            ApplicationDetails,
            ApplicationEdit,
            StateSelect
        },
        filters: {
            upper: (word) => {
                if (_.isNull(word)) {
                    return '';
                }
                else {
                    return String(word).toUpperCase();
                }
            },
            formatState: (state) => {
                switch (true) {
                    case _.isNull(state): return '';
                    case _.isString(state): return state;
                    case _.isObject(state) && _.has(state, 'stateAbbreviation'): return state.stateAbbreviation;
                    default: return state;
                }
            },
            formatDate: (date) => {
                const dateTime = formatters.mkDate(date);
                const formattedDateTime = formatters.dateTime(dateTime, true);
                return formattedDateTime;
            }
        }
    })

    export default class Applications extends Vue {
        ubcIdFilter = null;
        localUnionFilter = null;
        unionStatusFilter = null;
        classificationFilter = null;
        memberNameFilter = null;
        mentorNameFilter = null;
        contractorNameFilter = null;
        contractorStateFilter = null;
        appliedDateStartFilter = null;
        appliedDateEndFilter = null;
        processedDateStartFilter = null;
        processedDateEndFilter = null;
        applicationStatusFilter = this.allApplicationStatusType
        currPage = 1;
        perPage = 10;

        sort = {
            desc: true,
            by: 'appliedDate'
        };

        get appliedDateFilter() {
            return [this.appliedDateStartFilter, this.appliedDateEndFilter];
        }

        set appliedDateFilter([start, end]) {
            this.appliedDateStartFilter = start;
            this.appliedDateEndFilter = end;
        }

        get processedDateFilter() {
            return [this.processedDateStartFilter, this.processedDateEndFilter];
        }

        set processedDateFilter([start, end]) {
            this.processedDateStartFilter = start;
            this.processedDateEndFilter = end;
        }

        @Watch('ubcIdFilter')
        stripDashes(ubcId) {
            if (!_.isEmpty(ubcId)) {
                let newUbcId = ubcId;
                if (ubcId.includes('-')) {
                    newUbcId = ubcId.replace(/-/g, '');
                }
                this.ubcIdFilter = newUbcId.toUpperCase();
            }
        }

        get currentPage() {
            if (this.currPage > this.pages) {
                return this.pages;
            }
            else {
                return this.currPage;
            }
        }

        set currentPage(num) {
            const page = parseInt(num, 10);
            switch (true) {
                case _.isNaN(page):
                case 0 >= page:
                    this.currPage = 1;
                    break;
                case page > this.pages:
                    this.currPage = this.pages;
                    break;
                default:
                    this.currPage = page;
            }
        }

        get pages() {
            return Math.ceil(this.results.length / this.perPage) || 1;
        }

        get locals() {
            const locals = _.groupBy(this.$store.getters['common/locals'], (l) => sprintf("%s - %s", l.districtName, l.councilName));
            return _.map(locals, (ls, districtCouncil) => {
                return {
                    label: districtCouncil,
                    options: _.map(ls, (local) => {
                        return {
                            value: local.displayId,
                            text: sprintf("%s - %s, %s", local.displayId, local.city, local.state)
                        };
                    })
                };
            });
        }

        get classifications() {
            const classifications = _.chain(this.$store.getters['member/classifications'])
                .sortBy(function(classification) {
                    return classification;
                })
                .value();
            return classifications;
        }

        get applicationStatusTypes() {
            let statusTypes = [];
            for (let s of this.applicationStatuses) {
                if (!statusTypes.includes(s.value.type)) {
                    statusTypes.push(s.value.type);
                }
            }
            statusTypes.push(this.allApplicationStatusType);
            return statusTypes;
        }

        get allApplicationStatusType() {
            return 'All';
        }

        get applicationStatuses() {
            const results = _.chain(this.$store.getters['applications/getApplicationStatuses'])
                .map((status) => {
                    return {
                        value: status,
                        text: status.type
                    };
                })
                .value();
            return results;
        }

        get resultFields() {
            return [{
                key: 'ubcId',
                label: 'UBC ID',
                sortable: true
            }, {
                key: 'local',
                label: 'Local Union',
                sortable: true
            }, {
                key: 'unionStatus',
                label: 'Union Status',
                sortable: true
            }, {
                key: 'classification',
                label: 'Classification',
                sortable: true
            }, {
                key: 'memberName',
                label: 'Member Name',
                sortable: true
            }, {
                key: 'mentorName',
                label: 'Mentor Name',
                sortable: true
            },{
                key: 'contractorName',
                label: 'Contractor Name',
                sortable: true
            },{
                key: 'contractorState',
                label: 'Contractor State/Province',
                sortable: true
            },{
                key: 'session',
                label: 'Session',
                sortable: true
            },{
                key: 'status',
                label: 'Application Status',
                sortable: true
            },{
                key: 'appliedDate',
                label: 'Applied Date/Time',
                sortable: true,
                filterByFormatted: true
            },{
                key: 'statusDate',
                label: 'Processed Date/Time',
                sortable: true,
                filterByFormatted: true
            },{
                key: 'buttons',
                label: '',
                sortable: false
            }];
        }

        get results() {
            const applications = this.$store.getters['applications/getApplications'];
            // console.log(applications);
            const results =  _.chain(applications)
                .filter((application) => {
                    return !_.isNull(application) && !_.isEqual(application, NULL_APPLICATION);
                })
                .filter((application) => {
                    const filter = this.applicationStatusFilter;
                    return null === filter || this.allApplicationStatusType === filter || this.applicationStatusFilter == application.status.type;
                })
                .filter((application) => {
                    const filter = trimToNull(this.ubcIdFilter);
                    return null === filter || new RegExp(filter, 'i').test(application.member.ubcId);
                })
                .filter((application) => {
                    const filter = trimToNull(this.localUnionFilter);
                    return null === filter || new RegExp(filter, 'i').test(application.member.local);
                })
                .filter((application) => {
                    const filter = trimToNull(this.unionStatusFilter);
                    return null === filter || new RegExp(filter, 'i').test(application.member.unionStatus);
                })
                .filter((application) => {
                    const filter = trimToNull(this.classificationFilter);
                    return null === filter || new RegExp(filter, 'i').test(application.member.classification);
                })
                .filter((application) => {
                    const filter = trimToNull(this.memberNameFilter);
                    if (null === filter) {
                        return true;
                    }
                    const regexp = new RegExp(filter, 'i');
                    return regexp.test(application.member.fullName);
                })
                .filter((application) => {
                    const filter = trimToNull(this.mentorNameFilter);
                    if (null === filter) {
                        return true;
                    }
                    const regexp = new RegExp(filter, 'i');
                    return regexp.test(application.mentor.fullName);
                })
                .filter((application) => {
                    const filter = trimToNull(this.contractorNameFilter);
                    return null === filter || new RegExp(filter, 'i').test(application.contractor.name);
                })
                .filter((application) => {
                    const filter = this.contractorStateFilter;
                    if (null === filter) {
                        return true;
                    }
                    if (!application.contractor.state) {
                        return false;
                    }
                    return application.contractor.state instanceof State || application.contractor.state instanceof Object ?
                        application.contractor.state.id === filter.id :
                        (filter.stateAbbreviation.toLowerCase() === (application.contractor.state.toLowerCase()));
                })
                .filter((application) => {
                    const [start, end] = this.appliedDateFilter;

                    if (null === start || null === end) {
                        return true;
                    }

                    if (!application.appliedDate) {
                        return false;
                    }

                    const s = startOfDay(start);
                    const e = endOfDay(end);
                    const a = formatters.mkDate(application.appliedDate);

                    return isAfter(a, s) && isBefore(a, e);
                })
                .filter((application) => {
                    const [start, end] = this.processedDateFilter;

                    if (null === start || null === end) {
                        return true;
                    }

                    if (!application.statusDate) {
                        return false;
                    }

                    const s = startOfDay(start);
                    const e = endOfDay(end);
                    const a = formatters.mkDate(application.statusDate);

                    return isAfter(a, s) && isBefore(a, e);
                })
                .sortBy((application) => {
                    switch(this.sort.by) {
                        case 'ubcId':
                            return application.member.ubcId;
                        case 'local':
                            return application.member.local;
                        case 'unionStatus':
                            return application.member.unionStatus;
                        case 'classification':
                            return (application.member.classification || '').toLowerCase();
                        case 'memberName':
                            return (application.member.fullName || '').toLowerCase();
                        case 'mentorName':
                            return (application.mentor.fullName || '').toLowerCase();
                        case 'contractorName':
                            return ((application.contractor || {}).name || '').toLowerCase();
                        case 'contractorState':
                            return (((application.contractor || {}).state || {}).stateAbbreviation || '').toLowerCase();
                        case 'session':
                            return application.session.label;
                        case 'status':
                            return application.status.type;
                        case 'statusDate':
                            const sd = formatters.mkDate(application.statusDate);
                            return sd ? sd.valueOf() : -1;
                        case 'appliedDate':
                        default:
                            return formatters.mkDate(application.appliedDate).valueOf();
                    }
                })
                .value();

            return this.sort.desc ? results.reverse() : results;
        }

        clearFilters() {
            this.applicationStatusFilter = this.allApplicationStatusType;
            this.ubcIdFilter = null;
            this.localUnionFilter = null;
            this.unionStatusFilter = null;
            this.classificationFilter = null;
            this.memberNameFilter = null;
            this.mentorNameFilter = null;
            this.contractorNameFilter = null;
            this.contractorStateFilter = null;
            this.appliedDateStartFilter = null;
            this.appliedDateEndFilter = null;
            this.processedDateStartFilter = null;
            this.processedDateEndFilter = null;
        }

        async saveApplication(application) {
            // console.log('Saving application:');
            // console.log(application);
            try {
                await this.$store.dispatch('applications/saveApplication', application, {root: true});
                application.resetContractor();  //Reset contractor in case view before reload
                application.editMode = false;
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }

        async approveApplication(application) {
            //Update status
            const enrolled = this.$store.getters['applications/getEnrolledStatus'];
            application.workspace.status = enrolled;
            //Remove role status in case of prior status
            if (_.has(application.workspace.member, "roleStatus")) {
                delete application.workspace.member.roleStatus;
            }
            //Process application
            this.updateApplication(application);
            application.editMode = false;
        }

        async declineApplication(application) {
            //Reset contractor if no selection made in edit mode
            if (_.isEqual(application.workspace.contractor, NULL_ORGANIZATION)) {
                application.workspace.contractor = application.contractor;
            }
            //Status should already be set by user
            this.updateApplication(application);
            application.editMode = false;
        }

        async updateApplication(application) {
            // console.log('Updating application:');
            // console.log(application);
            try {
                await this.$store.dispatch('applications/updateApplication', application, {root: true});
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }

        async beforeRouteEnter(to, from, next) {
            store.commit('setBreadcrumbs', [
                Breadcrumb.create('Administration', {name: 'adminMain'}, false),
                Breadcrumb.create('Applications', {name: 'applications'}, true)
            ]);
            try {
                await store.dispatch('applications/loadApplications');
            }
            catch (error) {
                const vm = new Vue();
                vm.$bvToast.toast(error.message, errorToastOptions);
            }
            const deps = [
                'applications/loadApplicationStatuses',
                'common/loadStates',
                'common/loadLocals',
                'member/loadClassifications',
                'mentors/loadMemberMentors',
                'organizations/loadContractorTypes',
                'users/loadMentors',
                'tracks/loadTracks'
            ];
            _.each(deps, action => {
                store.dispatch(action)
                    .catch(error => {
                        const vm = new Vue();
                        vm.$bvToast.toast(error.message, errorToastOptions);
                    });
            });
            next();
        }
    }
</script>

<style scoped>

</style>